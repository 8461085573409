var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper add-ticket-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('validation-observer',{ref:"rules"},[_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{staticClass:"invoice-preview-card py-3",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pt-0"},[_c('b-row',[(_vm.user.userRole === 'Admin')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Send this Ticket to:","label-for":"user"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.allUsers)?_c('v-select',{staticClass:"mb-2",attrs:{"id":"user","options":_vm.allUsers,"label":"email","clearable":false},model:{value:(_vm.ticketDestinationName),callback:function ($$v) {_vm.ticketDestinationName=$$v},expression:"ticketDestinationName"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3244903566)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ticket Subject","label-for":"subject"}},[_c('validation-provider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subject","state":errors.length > 0 ? false:null,"placeholder":"Enter a Subject for your support ticket"},model:{value:(_vm.ticket.ticket_subject),callback:function ($$v) {_vm.$set(_vm.ticket, "ticket_subject", $$v)},expression:"ticket.ticket_subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Ticket Category:","label-for":"categ"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-2",attrs:{"id":"categ","options":_vm.ticketTypes,"clearable":false},on:{"input":_vm.UserUrgentNotification},model:{value:(_vm.ticket.ticket_type),callback:function ($$v) {_vm.$set(_vm.ticket, "ticket_type", $$v)},expression:"ticket.ticket_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Add an image/file (optional - e.g. screenshot) "),_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.focus.v-primary",modifiers:{"hover":true,"focus":true,"v-primary":true}}],class:'bi bi-info-circle',attrs:{"to":'',"title":'Accepted formats: zip, txt, html, htm, png, jpg, bmp, ico, gif, xlsx, csv, pdf'}})],1),_c('validation-provider',{attrs:{"name":"File","rules":"mimes:zip,text/plain,html,htm,image/png,image/jpeg,image/bmp,image/ico,image/gif,xlsx,csv,pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,text/csv"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file",staticStyle:{"display":"none"},attrs:{"id":"file","accept":".zip,.txt,.html,.htm,.png,.jpg,.bmp,.ico,.gif,.xlsx,.csv,.pdf","state":errors.length > 0 ? false:null,"multiple":"","plain":""},on:{"input":_vm.onChange},model:{value:(_vm.ticket.ticket_files),callback:function ($$v) {_vm.$set(_vm.ticket, "ticket_files", $$v)},expression:"ticket.ticket_files"}}),_c('br'),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.browse}},[_vm._v(" Browse... ")]),(_vm.filesAccumulated.length===0)?_c('small',[_vm._v(" No file/s selected.")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))])]}}])}),_vm._l((_vm.filesAccumulated),function(thisFile,index){return _c('div',{key:index},[_c('file-attachment',{attrs:{"filename":thisFile.name,"index":index},on:{"removeItemFromArray":_vm.removeItemAttachment}})],1)})],2)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Problem Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.user.userRole === 'Admin')?_c('quill-editor',{attrs:{"options":_vm.snowOption,"state":errors.length > 0 ? false:null,"placeholder":"Describe the problem that you have"},model:{value:(_vm.ticket.ticket_text),callback:function ($$v) {_vm.$set(_vm.ticket, "ticket_text", $$v)},expression:"ticket.ticket_text"}}):_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"placeholder":"Describe the problem that you have","rows":"10"},model:{value:(_vm.ticket.ticket_text),callback:function ($$v) {_vm.$set(_vm.ticket, "ticket_text", $$v)},expression:"ticket.ticket_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1),_c('b-col',{staticClass:"invoice-actions mt-md-0 mt-2",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":"","disabled":_vm.sending},on:{"click":_vm.submitHandler}},[_vm._v(" Send Ticket ")])],1)],1)],1),_c('b-modal',{attrs:{"id":"urgent-notification","title":"Urgent Category","no-close-on-backdrop":"","hide-header-close":"","ok-title":"Continue","size":"lg"}},[_c('h5',{staticClass:"px-2 text-center",staticStyle:{"margin-top":"20px"}},[_vm._v(" Please use the "),_c('strong',[_vm._v("\"Urgent\"")]),_vm._v(" tag only for issues that are truly urgent and affect the normal functionality of your platform."),_c('br'),_c('br'),_vm._v(" If an issue is not genuinely urgent, "),_c('strong',[_vm._v("a penalty of 100 euros")]),_vm._v(" may be added to your next invoice for the technical intervention, especially if it is done outside our office hours."),_c('br'),_vm._v(" Thank you! ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }